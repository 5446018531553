<template>
  <div>

    <b-card>
      <b-row>
        <b-col md="4">
          <b-row>
            <b-col cols="4">
              <b-button
                @click="previousMonth"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                />
              </b-button>
            </b-col>
            <b-col cols="4">
              <h4 style="text-transform: capitalize;">{{ new Intl.DateTimeFormat('pt-br', {
                month: 'long',
                timeZone: 'UTC',
              }).format(new Date(filters.selectedMonth)) }}</h4>
              <p class="text-muted">{{ new Intl.DateTimeFormat('pt-br', {
                year: 'numeric',
                timeZone: 'UTC',
              }).format(new Date(filters.selectedMonth)) }}</p>
            </b-col>
            <b-col
              cols="4"
              class="text-right"
            >
              <b-button
                @click="nextMonth"
              >
                <feather-icon
                  icon="ArrowRightIcon"
                  size="16"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="4">
          <label
            for="unit"
          >Unidade</label>
          <v-select
            id="unit"
            v-model="unit"
            label="title"
            :reduce="o => o.value"
            :clearable="false"
            :options="unitsChoices"
            @input="updateGraphs()"
          />
        </b-col>
        <b-col md="4">
          <label
            for="professor"
          >Professores</label>
          <v-select
            id="professor"
            v-model="filters.professors"
            multiple
            label="full_name"
            placeholder="Todos os professores"
            :reduce="p => p.id"
            :options="$store.state.quales.professors"
            @input="filterEvents"
          />
        </b-col>
        <b-col md="4">
          <label
            for="cliente"
          >Clientes</label>
          <v-select
            id="cliente"
            v-model="filters.clients"
            multiple
            label="full_name"
            placeholder="Todos os clientes"
            :reduce="p => p.id"
            :options="$store.state.quales.clients"
            @input="filterEvents"
          />
        </b-col>
        <b-col md="4">
          <label
            for="modalidade"
          >Modalidade</label>
          <v-select
            id="modalidade"
            v-model="filters.modalities"
            multiple
            label="name"
            placeholder="Todas as modalidades"
            :reduce="p => p.id"
            :options="$store.state.quales.modalities"
            @input="filterEvents"
          />
        </b-col>
        <b-col md="4">
          <label
            for="status"
          >Status</label>
          <v-select
            id="status"
            v-model="filters.status"
            label="title"
            :reduce="o => o.value"
            :clearable="false"
            :options="statuses"
            @input="filterEvents"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            class="mt-1 color-success"
            @click="getReport"
          >
            <div
              v-if="pdfLoading"
              class="spinner-border"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <feather-icon
                icon="FileTextIcon"
                size="16"
              />
              Baixar Relatório Mensal: {{ conteudo }}
            </div>
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="!loading"
      no-body
      class="card-revenue-budget"
    >
      <b-row class="mx-0">
        <b-col
          md="12"
          class="revenue-report-wrapper"
        >
          <div class="d-sm-flex justify-content-between align-items-center mb-3">
            <h4 class="card-title mb-50 mb-sm-0">
              Dashboard
            </h4>
          </div>

          <vue-apex-charts
            type="bar"
            height="350"
            :options="bar.chartOptions"
            :series="bar.series"
          />
          <vue-apex-charts
            type="donut"
            height="350"
            :options="donut.chartOptions"
            :series="donut.series"
          />
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from '@axios'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    // BFormDatepicker,
    vSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pdfLoading: false,
      loading: true,
      conteudo: 'usuário',
      data: null,
      filters: {
        startDate: '',
        endDate: '',
        professors: [],
        clients: [],
        modalities: [],
        status: true,
        selectedMonth: '2021-05',
      },
      unit: 'students',
      unitsChoices: [
        {
          value: 'aulas',
          title: 'Aulas',
        },

        {
          value: 'students',
          title: 'Alunos',
        },
      ],
      statuses: [
        {
          title: 'Qualquer status',
          value: null,
        },
        {
          title: 'Validadas',
          value: true,
        },
        {
          title: 'Não validadas',
          value: false,
        },
      ],
      bar: {
        series: [],
        chartOptions: {
          chart: {
            type: 'bar',
            // height: 350,
            horizontal: true,
            // stacked: true,
            toolbar: {
              show: false,
            },
            // zoom: {
            //   enabled: true,
            // },
          },
          // responsive: [{
          //   breakpoint: 480,
          //   options: {
          //     legend: {
          //       position: 'bottom',
          //       offsetX: -10,
          //       offsetY: 0,
          //     },
          //   },
          // }],
          plotOptions: {
            bar: {
              borderRadius: 8,
              horizontal: false,
              columnWidth: '70%',
              barHeight: '100%',
            },
          },
          xaxis: {
            type: 'category',
            categories: [],
            tickAmount: 31,
            labels: {
              formatter(val) {
                return new Intl.DateTimeFormat('pt-br', {
                  hour12: false,
                  day: 'numeric',
                  month: 'long',
                  timeZone: 'UTC',
                }).format(new Date(val))
              },
            },
          },
          yaxis: {
            // title: {
            //   text: undefined,
            // },
          },
          legend: {
            position: 'right',
            offsetY: 40,
          },
          fill: {
            opacity: 1,
          },
        },
      },
      donut: {
        series: [],
        chartOptions: {
          labels: [],
          chart: {
            type: 'donut',
            width: 200,
            height: 200,
          },
          // legend: {
          //   position: 'bottom',
          // },
          // responsive: [{
          //   breakpoint: 480,
          //   options: {
          //     chart: {
          //       width: 200,
          //     },
          //     legend: {
          //       position: 'bottom',
          //     },
          //   },
          // }],
        },
      },
    }
  },
  created() {
    const date = new Date((new Date()).getFullYear(), (new Date()).getMonth())
    this.filters.selectedMonth = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`
    this.$store.dispatch('quales/getProfessors')
    this.$store.dispatch('quales/getClients')
    this.$store.dispatch('quales/getModalities')
    this.$store.dispatch('courses/getStats', this.filters).then(result => {
      this.data = result
      this.updateGraphs()
    })
      .catch(error => {
        if (error.message === 'Not logged in') {
          this.$store.dispatch('user/logout')
          this.$router.push({ name: 'auth-login' })
        }
      })
  },
  methods: {
    filterEvents() {
      this.loading = true
      this.conteudo = this.filters.clients.length === 1 ? `${this.$store.state.quales.clients.find(item => item.id === this.filters.clients[0]).full_name}` : 'usuário'
      this.$store.dispatch('courses/getStats', this.filters).then(result => {
        this.data = result
        this.updateGraphs()
      })
    },
    updateGraphs() {
      this.loading = true
      if (!this.data) {
        return
      }
      this.bar.series = []
      this.bar.series.push({
        name: this.unitsChoices.find(item => item.value === this.unit).title,
        data: this.data.monthly_stats.sort((a, b) => new Date(a.day) - new Date(b.day)).map(item => item[this.unit]),
      })
      this.bar.chartOptions.xaxis.categories = this.data.monthly_stats.map(item => item.day).sort((a, b) => new Date(a) - new Date(b))
      this.donut.chartOptions.labels = this.data.modalities_stats.map(item => item.modality)
      this.donut.series = this.data.modalities_stats.map(item => item[this.unit])
      this.loading = false
    },
    nextMonth() {
      const date = new Date(this.filters.selectedMonth.slice(0, 4), this.filters.selectedMonth.slice(5, 7) - 1)
      const newDate = new Date(date.getFullYear(), date.getMonth() + 1)
      this.filters.selectedMonth = `${newDate.getFullYear()}-${(newDate.getMonth() + 1).toString().padStart(2, '0')}`
      this.filterEvents()
    },
    previousMonth() {
      const date = new Date(this.filters.selectedMonth.slice(0, 4), this.filters.selectedMonth.slice(5, 7) - 1)
      const newDate = new Date(date.getFullYear(), date.getMonth() - 1)
      this.filters.selectedMonth = `${newDate.getFullYear()}-${(newDate.getMonth() + 1).toString().padStart(2, '0')}`
      this.filterEvents()
    },
    getReport() {
      this.pdfLoading = true
      const client = this.filters.clients.length === 1 ? `${this.filters.clients[0]}/` : ''
      return axios.get(`/courses/pdf/${this.filters.selectedMonth.slice(0, 4)}/${this.filters.selectedMonth.slice(5, 7)}/${client}`, { responseType: 'blob' })
        .then(response => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' },
          )
          const fileURL = URL.createObjectURL(file)
          window.open(fileURL)
          this.pdfLoading = false
        })
        .catch(() => {
          this.pdfLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
